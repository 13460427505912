import Role from "@/assets/constants/role";
import { mapGetters, mapState } from "vuex";

const auth = {
    computed: {
        isAdmin() {
            return Role.ADMIN === this._user.role;
        },
        ...mapState("auth", {
            _user: "user",
            _device_id: "device_id"
        })
    }
};

export default auth;