import Vue from "vue";
import Vuex from "vuex";
import createMutationsSharer from "vuex-shared-mutations";
import createPersistedState from "vuex-persistedstate";

//let's auto import modules
const module_files = require.context("./modules", false,  /\.js$/);
const modules = {};
module_files.keys().forEach(module_file => {
    // create the module name from fileName
    // remove the store.js extension
    const module = module_file.replace(/(\.\/|\.js)/g, "");
    modules[module] = module_files(module_file).default || module_files(module_file);
});

Vue.use(Vuex);

export default new Vuex.Store({
    modules,
    plugins: [
        createMutationsSharer({
            predicate: [
                "auth/auth_token",
                "auth/user",
                "auth/device_id"
            ]
        }),
        createPersistedState()
    ]
});
