import Vue from "vue";
import App from "./App.vue";
import http from "./http";
import i18n from "./i18n";
import router from "./router";
import store from "./store";

import moment from "moment";
import VueMoment from "vue-moment";
import Toasted from "vue-toasted";
import { isMobile, isMobileOnly, isTablet } from "mobile-device-detect";
import VueConfirmDialog from "@/components/vue-confirm-dialog";

import util from "./mixins/util";
import auth from "./mixins/auth";

import gender from "./assets/constants/gender";

import "fomantic-ui-css/semantic.min.js";
import "fomantic-ui-css/semantic.min.css";
import "./assets/fonts/fontello/css/fontello.css";
import "./assets/styles/style.scss";

Vue.prototype.$http = http;

Vue.config.productionTip = false;
Vue.use(VueMoment, { moment });
Vue.use(Toasted, {
    position: "bottom-right",
    iconPack: "callback"
});
Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

Vue.mixin(util);
Vue.mixin(auth);

Vue.prototype.$gender = gender;
Vue.prototype.$isMobile = isMobile;
Vue.prototype.$isMobileOnly = isMobileOnly;
Vue.prototype.$isTablet = isTablet;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount("#app");