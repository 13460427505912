import http from "@/http";

const auth = {
    namespaced: true,
    state: {
        auth_token: "",
        device_id: "",
        user : {}
    },
    getters: {
        isAuthenticated: (state) => {
            return !!state.auth_token;
        }
    },
    mutations: {
        auth_token(state, auth_token) {
            state.auth_token = auth_token;
            if(auth_token) {
                http.defaults.headers.common["Authorization"] = `Bearer ${state.auth_token}`;
                http.defaults.headers.common["x-session-token"] = state.auth_token;
            } else {
                delete http.defaults.headers.common["Authorization"];
                delete http.defaults.headers.common["x-session-token"];
            }
        },
        device_id(state, device_id) {
            state.device_id = device_id;
        },
        user(state, user) {
            state.user = user;
        }
    },
    actions: {
        login({commit, dispatch}, auth_object) {
            return new Promise((resolve, reject) => {
                try {
                    const auth_token = auth_object.auth_token;
                    const user = auth_object.user;
                    if(!auth_token || !user) {
                        dispatch("auth_error");
                        throw new Error("Invalid authentication object.");
                    }
                    commit("auth_token", auth_object.auth_token);
                    commit("user", auth_object.user);
                    resolve();
                } catch(error) {
                    reject(error);
                }
            });
        },
        auth_error({dispatch}) {
            return new Promise((resolve, reject) => {
                dispatch("logout");
                resolve();
            });
        },
        updateUser({commit}, user) {
            return new Promise((resolve, reject) => {
                try {
                    if(!user) {
                        throw new Error("Invalid user object.");
                    }

                    commit("user", user);

                    resolve();
                } catch(error) {
                    reject(error);
                }
            });
        },
        updateDeviceID({commit}, device_id) {
            return new Promise((resolve, reject) => {
                commit("device_id", device_id);
                resolve();
            });
        },
        logout({commit}) {
            return new Promise((resolve, reject) => {
                commit("auth_token", "");
                commit("user", {});
                resolve();
            });
        }
    }
};

export default auth;