import axios from "axios";
import store from "@/store";
import NProgress from "nprogress";

import "nprogress/nprogress.css";

const http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 30000
});

NProgress.configure({
    showSpinner: false
});

http.interceptors.request.use(httpconfig => {
    // before a request is made start the nprogress
    NProgress.start();
    return httpconfig;
});

// before a response is returned stop nprogress
http.interceptors.response.use(response => {
    NProgress.done();
    return response;
}, error => {
    NProgress.done();
    return Promise.reject(error);
});

/*http.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const originalRequest = error.config;

    if(error.response.status === 401) {
        if(http._authenticating) {
            return http(originalRequest);
        }

        if(!originalRequest._retry) {
            http._authenticating = true;

            const response = await http.post("auth/", {
                applicationKey: http._app_key,
                customerID: http._customer_id
            });

            const auth_token = response.data;
            if(auth_token) {
                http.setAuthToken(auth_token);

                originalRequest._retry = true;
                return http(originalRequest);
            }

            delete http._authenticating;
        }
    }
    return Promise.reject(error);
});*/

const auth_token = store.state.auth.auth_token;
if(auth_token) {
    http.defaults.headers.common["Authorization"] = `Bearer ${auth_token}`;
    http.defaults.headers.common["x-session-token"] = auth_token;
}

export default http;