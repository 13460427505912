import http from "@/http";

const outstanding = {
    namespaced: true,
    state: {
        count: 0,
        polling: null,
        error: null
    },
    mutations: {
        count(state, count) {
            state.count = count;
        },
        error(state, error) {
            state.error = error;
        },
        polling(state, polling) {
            if(polling) {
                state.polling = polling;
            } else {
                if(state.polling) {
                    clearInterval(state.polling);
                    state.polling = null;
                }
            }
        }
    },
    actions: {
        fetchUpdate({ commit }) {
            return http.head("outstanding-aftersales").then((response) => {
                const total_count = response.headers["x-total-count"] || null;

                commit("count", +total_count);
                commit("error", null);
            }).catch((error) => {
                commit("count", -1);
                commit("error", error);

                console.error(error);
            });
        },
        startPolling({ commit, dispatch }) {
            return new Promise((resolve, reject) => {
                commit("polling", null);

                commit("polling", setInterval(() => {
                    dispatch("fetchUpdate");
                }, 60000));

                resolve();
            });
        },
        stopPolling({ commit }) {
            commit("polling", null);
        }
    }
};

export default outstanding;