import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import store from "@/store";
import http from "@/http";
import Role from "@/assets/constants/role";
import { parsers } from "www-authenticate";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        meta: {
            requiresAuth: true
        },
        component: () => import("@/views/Dashboard")
    },
    {
        path: "/did-not-register",
        name: "DidNotRegister",
        meta: {
            layout: "blank"
        },
        component: () => import("@/pages/DidNotRegister")
    },
    {
        path: "/did-not-request-temp-password",
        name: "DidNotRequestTempPassword",
        meta: {
            layout: "blank"
        },
        component: () => import("@/pages/DidNotRequestTempPassword")
    },
    {
        path: "/did-not-change-password",
        name: "DidNotChangePassword",
        meta: {
            layout: "blank"
        },
        component: () => import("@/pages/DidNotChangePassword")
    },
    {
        path: "/device-id",
        name: "DeviceID",
        meta: {
            layout: "blank"
        },
        component: () => import("@/pages/DeviceID")
    },
    {
        path: "/verify-device",
        name: "VerifyDevice",
        meta: {
            layout: "blank"
        },
        component: () => import("@/pages/VerifyDevice")
    },
    {
        path: "/verify-device/:device_id",
        name: "ApproveDevice",
        meta: {
            layout: "blank",
            requiresAuth: true,
            roles: [Role.ADMIN]
        },
        component: () => import("@/pages/ApproveDevice")
    },
    {
        path: "/verify-email",
        name: "VerifyEmail",
        meta: {
            layout: "blank"
        },
        component: () => import("@/pages/VerifyEmail")
    },
    {
        path: "/customers",
        name: "Customers",
        meta: {
            requiresAuth: true,
            roles: [Role.ADMIN, Role.CONSULTANT]
        },
        component: () => import("@/views/Customers")
    },
    {
        path: "/customers/:id",
        name: "Customer",
        meta: {
            requiresAuth: true,
            roles: [Role.ADMIN, Role.CONSULTANT]
        },
        component: () => import("@/views/Customer")
    },
    {
        path: "/outstandings",
        name: "OutstandingRecords",
        meta: {
            requiresAuth: true,
            roles: [Role.ADMIN, Role.CONSULTANT]
        },
        component: () => import("@/views/Outstandings")
    },
    {
        path: "/prescriptions/:id",
        name: "Prescription",
        meta: {
            requiresAuth: true,
            roles: [Role.ADMIN, Role.CONSULTANT]
        },
        component: () => import("@/views/Prescription")
    },
    {
        path: "/login",
        name: "Login",
        meta: {
            layout: "blank",
            requiresNoAuth: true
        },
        component: () => import("@/pages/Login")
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        meta: {
            layout: "blank",
            requiresNoAuth: true
        },
        component: () => import("@/pages/ForgotPassword")
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        meta: {
            layout: "blank",
            requiresNoAuth: true
        },
        component: () => import("@/pages/ResetPassword")
    },
    {
        path: "/profile",
        name: "Profile",
        meta: {
            requiresAuth: true
        },
        component: () => import("@/views/Profile")
    },
    {
        path: "/change-password",
        name: "ChangePassword",
        meta: {
            requiresAuth: true
        },
        component: () => import("@/views/ChangePassword")
    },
    {
        path: "/users",
        name: "Users",
        meta: {
            requiresAuth: true,
            roles: [Role.ADMIN]
        },
        component: () => import("@/views/Users")
    },
    {
        path: "/users/:id",
        name: "User",
        meta: {
            requiresAuth: true,
            roles: [Role.ADMIN]
        },
        component: () => import("@/views/User")
    },
    {
        path: "/outlets",
        name: "Outlets",
        meta: {
            requiresAuth: true,
            roles: [Role.ADMIN]
        },
        component: () => import("@/views/Outlets")
    },
    {
        path: "/treatments",
        name: "Treatments",
        meta: {
            requiresAuth: true,
            roles: [Role.ADMIN]
        },
        component: () => import("@/views/Treatments")
    },
    {
        path: "/referred",
        name: "Referred",
        meta: {
            requiresAuth: true,
            roles: [Role.ADMIN, Role.CONSULTANT]
        },
        component: () => import("@/views/ReferredRegistrations")
    },
    {
        path: "/devices",
        name: "Devices",
        meta: {
            requiresAuth: true,
            roles: [Role.ADMIN]
        },
        component: () => import("@/views/Devices")
    },
    {
        path: "*",
        beforeEnter: (to, from, next) => {
            const base = process.env.BASE_URL || "";
            return window.location.href = (base.endsWith("/")? base : `${base}/`) + "404.html";
        }
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            let delay = 0;
            if(savedPosition) {
                delay = 1000;
            }

            let position = savedPosition || {
                x: 0,
                y: 0
            };

            if(to.hash) {
                position = {
                    selector: to.hash
                };
                delay = 0;
            }

            setTimeout(() => {
                resolve(position);
            }, delay);
        });
    }
});

router.beforeEach(async (to, from, next) => {
    // Add authentication interceptor here
    // https://scotch.io/tutorials/handling-authentication-in-vue-using-vuex
    if(to.matched.some(record => record.meta.requiresAuth)) {
        //check token validity
        if(store.getters["auth/isAuthenticated"]) {
            await new Promise((resolve, reject) => {
                http.get("auth").then((response) => {
                    return {
                        valid: true,
                        status_code: response.status
                    };
                }).catch((error) => {
                    if(!!error.isAxiosError && !error.response) {
                        //NETWORK ERROR
                        return {
                            valid: false,
                            message: "Check your network connection."
                        };
                    }

                    if("ECONNABORTED" === error.code) {
                        console.warn("Token authenticate timed out.");
                        //timeout, could be server busy, let it pass first on benefit of doubt
                        return {
                            valid: true,
                            status_code: 200
                        };
                    }

                    if(401 === error.response?.status) {
                        //try to get the www-authentication
                        try {
                            if(error.response?.headers && error.response.headers["www-authenticate"]) {
                                // meaning jwt session timeout
                                const www_authenticate = error.response.headers["www-authenticate"];
                                const parsed = new parsers.WWW_Authenticate(www_authenticate);
                                const realm = parsed.parms?.realm || "";

                                if(realm) {
                                    sessionStorage.setItem("realm", realm);
                                }
                            }
                        } catch(_error) {
                            console.error(_error);
                        }
                    }

                    const status_code = error.response?.status || 401;
                    return {
                        valid: false,
                        status_code
                    };
                }).then(({ valid, status_code, message = "" }) => {
                    if(valid) {
                        return resolve();
                    }

                    if(!status_code) {
                        Vue.toasted.show(message || "Unable to complete request to server.", {
                            duration: 3000
                        });

                        resolve();
                    } else if(status_code === 401 || status_code === 403) {
                        store.dispatch("auth/auth_error").finally(() => {
                            Vue.toasted.show("Login session timeout.", {
                                duration: 3000
                            });

                            resolve();
                        });
                    } else {
                        Vue.toasted.error(`Request failed with status code ${status_code}`, {
                            duration: 3000
                        });

                        resolve();
                    }
                });
            });
        }

        if(!store.getters["auth/isAuthenticated"]) {
            return next({
                name: "Login",
                params: {
                    to: to,
                    from: from
                }
            });
        }
    }

    if(to.matched.some(record => record.meta.requiresNoAuth)) {
        if(store.getters["auth/isAuthenticated"]) {
            return next({
                name: "Home"
            });
        }
    }

    const user = store?.state?.auth?.user || {};
    if(to.matched.some(record => (record.meta.roles && record.meta.roles.length && !record.meta.roles.includes(user.role)))) {
        const base = process.env.BASE_URL || "";
        return window.location.href = (base.endsWith("/")? base : `${base}/`) + "403.html";
    }

    next();
});

/* BEGIN NPROGRESS */
NProgress.configure({
    showSpinner: false
});

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if(to.name) {
        NProgress.start();
    }
    next();
});

router.afterEach((to, from) => {
    NProgress.done();
});
/* END NPROGRESS */

export default router;