<template>
    <div>
        <component :is="layout"/>
        <vue-confirm-dialog></vue-confirm-dialog>
    </div>
</template>

<script>
const BlankLayout = () => import("@/layouts/Blank");
const DefaultLayout = () => import("@/layouts/Default");

export default {
    components: {
        BlankLayout,
        DefaultLayout
    },
    computed: {
        layout() {
            return (this.$route.meta.layout || "default") + "-layout";
        }
    }
};
</script>