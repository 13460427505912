import debounce from "lodash.debounce";
import moment from "moment";

const util = {
    directives: {
        uppercase: {
            update(el, binding, vnode) {
                if(el.value) {
                    el.value = el.value.toUpperCase();
                    el.dispatchEvent(new Event("input"));
                }
            }
        },
        lowercase: {
            update(el, binding, vnode) {
                if(el.value) {
                    el.value = el.value.toLowerCase();
                    el.dispatchEvent(new Event("input"));
                }
            }
        }
    },
    filters: {
        striphtml(value) {
            if(!value) {
                return "";
            }

            let div = document.createElement("div");
            value = value.replace(/<br\s*\/?>/gi, " "); //this is to change <br/> to whitespace to improve readability after stripped
            div.innerHTML = value;

            let text = div.textContent || div.innerText || "";
            return text;
        },
        uppercase(text) {
            if(!text) {
                return "";
            }
            return text.toUpperCase();
        },
        lowercase(text) {
            if(!text) {
                return "";
            }
            return text.toLowerCase();
        },
        capitalize(text) {
            if(!text) {
                return "";
            }
            return text.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
        },
        appendtimestamp(src) {
            if(!src) {
                return "";
            }
            let expiry_duration = 5 * 60 * 1000; // 5 minutes
            let url = new URL(src);
            let search_params = url.searchParams;

            search_params.set("timestamp", Math.floor(Date.now() / expiry_duration));
            url.search = search_params.toString();

            return url.toString();
        },
        stripspace(text) {
            if(!text) {
                return "";
            }
            return  text.replace(/\s/g, "");
        },
        tocurrency(value) {
            if(!value && value !== 0) {
                return "";
            }
            return value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        },
        formatdate(date, format) {
            if(!date || !format) {
                return "";
            }
            return moment(date).format(format);
        },
        displayname(user) {
            if(!user) {
                return "";
            }
            return (`${user?.first_name || ""} ${user?.last_name || ""}`).trim();
        }
    },
    data() {
        return {
            util: {
                prev_route: null,
                window_width: window.innerWidth,
                breakpoint: {
                    tablet: 992,
                    mobile: 767
                },
                resizeHandler: debounce(() => {
                    this.util.window_width = window.innerWidth;
                }, 100)
            }
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.util.prev_route = from;
        });
    },
    mounted() {
        window.addEventListener("resize", this.util.resizeHandler);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.util.resizeHandler);
    },
    computed: {
        backRoute() {
            if(this.util.prev_route && this.util.prev_route.name && this.util.prev_route.fullPath !== this.$router.currentRoute.fullPath) {
                return {
                    name: this.util.prev_route.name,
                    hash: this.util.prev_route.hash,
                    query: this.util.prev_route.query,
                    params: this.util.prev_route.params
                };
            }
            return null;
        },
        is_sm() {
            return this.util.window_width <= this.util.breakpoint.mobile;
        },
        is_md() {
            return this.util.window_width > this.util.breakpoint.mobile && this.util.window_width <= this.util.breakpoint.tablet;
        },
        is_lg() {
            return this.util.window_width > this.util.breakpoint.tablet;
        }
    },
    methods: {
        isBlankObject(data) {
            let isBlank = function(obj) {
                let is_blank = true;

                if(obj) {
                    for(var key in obj) {
                        let value = obj[key];
                        if(typeof value === "object") {
                            if(!isBlank(value)) {
                                is_blank = false;
                                break;
                            }
                        } else if(value) {
                            is_blank = false;
                            break;
                        }
                    }
                }

                return is_blank;
            };

            return isBlank(data);
        },
        getDateFormat() {
            try {
                // initialize date value "31st January 2019"
                var my_date = new Date(2019, 0, 31);
                // Initialize variables
                var separator = "";
                var first = "";
                var second = "";
                var third = "";
                var date_parts = [];

                // get separator : "-", "/" or " ", format based on toLocaleDateString function
                if(my_date.toLocaleDateString().split("-").length === 3) {
                    separator = "-";
                } else if(my_date.toLocaleDateString().split("/").length === 3) {
                    separator = "/";
                } else if(my_date.toLocaleDateString().split(" ").length === 3) {
                    separator = " ";
                }

                if(!separator) {
                    throw Error("Unable to identify date format separator");
                }

                date_parts = my_date.toLocaleDateString().split(separator);

                // get first part
                if(date_parts[0] === "2019") {
                    first = "yyyy";
                } else if(date_parts[0] === "31") {
                    first = "dd";
                } else {
                    if(date_parts[0].length <= 2) {
                        first = "mm";
                    } else {
                        first = "mmm";
                    }
                }

                // get second part
                if(date_parts[1] === "2019") {
                    second = "yyyy";
                } else if(date_parts[1] == "31") {
                    second = "dd";
                } else {
                    if(date_parts[1].length <= 2) {
                        second = "mm";
                    } else {
                        second = "mmm";
                    }
                }

                // get third part
                if(date_parts[2] === "2019") {
                    third = "yyyy";
                } else if(date_parts[2] === "31") {
                    third = "dd";
                } else {
                    if(date_parts[2].length <= 2) {
                        third = "mm";
                    } else {
                        third = "mmm";
                    }
                }

                // assembly
                const format = first + separator + second + separator + third;
                return format;
            } catch(error) {
                console.error(error);
                return null;
            }
        }
    }
};

export default util;
